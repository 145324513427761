<template>
  <div class="guide-list-channel-column-header">
    <div class="guide-list-channel-column-header-item first-row">
      {{ $_date() }}
    </div>

    <div class="guide-list-time-content">
      <div
        v-for="(item, index) in $_timeLineMarks()"
        :key="index"
        :class="item.class"
        :style="item.style"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
import formatTimestampToHHMM from "@/modules/guide/helpers/formatTimestampToHHMM.js";

/**
 * Cantidad de minutos que se mostrará una viñeta.
 *
 * @private
 *
 * @type {Number}
 */
const $_numberOfMinutesToShowABullet = 10;

export default {
  name: "GuideTraditionalTimeLine",

  inheritAttrs: false,

  props: {
    /**
     * Timestamp que representará el inicio de la línea de tiempo.
     */
    startTime: {
      type: Number,
      required: true,
    },

    /**
     * Timestamp que representará el final de la línea de tiempo.
     */
    endTime: {
      type: Number,
      required: true,
    },

    pixelsInOneMinute: {
      type: Number,
      required: false,
      default: 9,
    },

    /**
     * Función que convertirá la fecha a mostrar en la
     * línea de tiempo.
     */
    dateInTimeline: {
      type: Function,
      required: true,
    },
  },

  computed: {},

  methods: {
    /**
     * Retorna la fecha a mostrar en la línea de tiempo.
     *
     * @private
     */
    $_date() {
      return this.dateInTimeline(this.startTime);
    },

    /**
     * Contiene lógica para el renderizado de viñetas u hora.
     *
     * @private
     */
    $_timeLineMarks() {
      let items = [];

      let startTime = new Date(this.startTime);

      let minutesStartTime = startTime.getMinutes();

      minutesStartTime -= minutesStartTime % $_numberOfMinutesToShowABullet;

      startTime.setMinutes(minutesStartTime);
      startTime = new Date(startTime).getTime();

      let endTime = new Date(this.endTime);
      // Se agrega dos horas más a la línea de tiempo ya que
      // la maqueta "se rompe" al renderizar ítems
      // por solamente 3 horas
      endTime.setHours(endTime.getHours() + 2, 0, 0, 0);
      endTime = new Date(endTime).getTime();

      // Esta cuenta se realiza debido a que el bullet
      // u hora se encuentra con el texto centrado.
      const initialLeftPx =
        0 -
        (this.pixelsInOneMinute * ($_numberOfMinutesToShowABullet / 2) +
          this.pixelsInOneMinute *
            (new Date(this.startTime).getMinutes() %
              $_numberOfMinutesToShowABullet));

      while (startTime < endTime) {
        const date = new Date(startTime);
        const minutes = date.getMinutes();

        if (minutes === 0 || minutes === 30) {
          items.push({
            style: {
              left: initialLeftPx + "px",
              width: this.pixelsInOneMinute * 10 + "px",
            },
            class: "time",
            text: formatTimestampToHHMM(date),
          });
        } else {
          items.push({
            style: {
              left: initialLeftPx + "px",
              width: this.pixelsInOneMinute * 10 + "px",
            },
            class: "bullet",
            text: "•",
          });
        }

        startTime = date.setMinutes(minutes + $_numberOfMinutesToShowABullet);
      }

      return items;
    },
  },
};
</script>

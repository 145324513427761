/**
 * Convierte milisegundos a minutos.
 *
 * @param {Number} milliseconds
 *
 * @returns {Number}
 */
function convertMillisecondsToMinutes(milliseconds) {
	if (!milliseconds) {
		return 0;
	}

	return milliseconds / 1000 / 60;
}

export default convertMillisecondsToMinutes;

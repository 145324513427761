function getPrograms(channel = {}) {
	if (!channel?.programs || channel?.programs?.length === 0) {
		return [];
	}

	return channel.programs;
}

function getChannelLcn(channel = {}) {
	return channel.lcn || undefined;
}

function getChannelLogo(channel = {}) {
	return channel.logo || channel.logoCanal || undefined;
}

function getChannelTitle(channel = {}) {
	return channel.name || undefined;
}

function getProgramIdAsset(program = {}) {
	return program.idAsset || undefined;
}

/**
 * Retorna el índice del arreglo channels perteneciente al canal
 * cuya propiedad lcn coincide con el enviado por parámetro.
 * En caso de no encontrarse un resultado retorna 0 (cero).
 *
 * @param {Array<Channel>} channels.
 * @param {Number} lcn - List Channel Number.
 *
 * @returns {Number}
 *
 * @todo Retornar el lcn más cercano al seleccionado.
 */
function getChannelIndexByLcn(channels, lcn) {
	const index = channels.findIndex(
		(channel) => getChannelLcn(channel) === lcn
	);

	return Math.max(0, index);
}

/**
 * Retorna el índice del arreglo programs perteneciente al programa
 * de un determinado canal cuya propiedad idAsset coincide con el enviado por parámetro.
 * En caso de no encontrarse un resultado retorna 0 (cero).
 *
 * @param {Array<Programs>} programs.
 * @param {Number} idAsset - Id program.
 *
 * @returns {Number}
 *
 * @todo Retornar el idAsset más cercano al seleccionado.
 */
function getProgramIndexByIdAsset(programs, idAsset) {
	const index = programs.findIndex(
		(program) => getProgramIdAsset(program) === idAsset
	);

	return Math.max(-1, index);
}

function getProgramsByChannelIndex(channels, index) {
	return channels[index].programs;
}

export {
	getPrograms,
	getChannelLcn,
	getChannelLogo,
	getChannelTitle,
	getChannelIndexByLcn,
	getProgramIndexByIdAsset,
	getProgramsByChannelIndex,
};

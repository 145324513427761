import convertSecondsToMs from '@/modules/guide/helpers/convertSecondsToMs';

function getProgramId(program = {}) {
	return program.idAsset || program.id || undefined;
}

function getProgramTitle(program = {}) {
	return program.title || undefined;
}

function getProgramStartTime(program = {}) {
	const time = program.startHourUnixTime || program.startTime || undefined;
	return convertSecondsToMs(time);
}

function getProgramEndTime(program = {}) {
	const time = program.endHourUnixTime || program.endTime || undefined;
	return convertSecondsToMs(time);
}

function getProgramIsLive(program = {}) {
	const now = Date.now();

	return (
		getProgramStartTime(program) <= now && getProgramEndTime(program) >= now
	);
}

/**
 * Retorna el índice del array programs perteneciente al programa
 * cuya ventana de tiempo coincide con el enviado por parámetro.
 * En caso de no encontrar algún índice retorna 0.
 *
 * @param {Array<Program>} programs
 * @param {Number} startTime - Timestamp
 * @param {Number} endTime - Timestamp
 *
 * @returns {Number}
 */
function getProgramIndexByTimeWindow(programs, startTime, endTime) {
	const $_startTime = Math.min(startTime, endTime);
	const $_endTime = Math.max(startTime, endTime);

	const index = programs.findIndex(
		(program) =>
			getProgramEndTime(program) > $_startTime &&
			getProgramStartTime(program) <= $_endTime
	);

	return Math.max(-1, index);
}

/**
 * Retorna el índice del array programs perteneciente al programa
 * cuya ventana de tiempo coincide con el enviado por parámetro.
 * En caso de no encontrar algún índice retorna 0.
 *
 * @param {Array<Program>} programs
 * @param {Number} startTime - Timestamp
 * @param {Number} endTime - Timestamp
 *
 * @returns {Number}
 */
function getProgramIndexByTime(programs, time) {
	time = convertSecondsToMs(time);

	const index = programs.findIndex(
		(program) =>
			time >= getProgramStartTime(program) &&
			time <= getProgramEndTime(program)
	);

	return Math.max(-1, index);
}

export {
	getProgramId,
	getProgramTitle,
	getProgramStartTime,
	getProgramEndTime,
	getProgramIsLive,
	getProgramIndexByTimeWindow,
	getProgramIndexByTime,
};

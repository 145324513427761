<template>
  <div class="guide-list-programs" id="guide_programs">
    <div class="guide-list-programs-item-content">
      <div
        v-for="(channel, index) in channels"
        :key="index"
        :class="{
          focused: isFocusedChannel(channel),
        }"
        class="first-col"
        :ref="'lcn' + channel.lcn"
      >
        <div
          class="first-row guide-list-programs-item"
          :class="{ selected: isScrolledChannel(channel) }"
        >
          <div class="number">{{ getChannelLcn(channel) }}</div>
          <div class="logo" :style="getChannelLogo(channel)">
            <!-- 
					 - TODO: Reemplazar por componente IMG que contenga 
					 		 lazy loading o IntersectionObserver

					 - TODO: Agregar atributos decoding="async" loading="lazy"

					 - TODO: Crear helper getLogo para mostrar logos
					 		 por default de los canales que no poseen logos

					 - TODO: Para mejorar la performance, los logos de los canales
					         deben de mostrarse por medio de CSS con background.
							 (Pasar a equipo UI el CSS ubicado en el componente)

					 - TODO: DCIW362 no muestra nombre de canal en caso de no poseer
					         logo.
					-->
            <!-- <img
						:src="getChannelLogo(channel)"
						:alt="getChannelTitle(channel)"
					/> -->
          </div>
        </div>
      </div>
    </div>
    <div class="guide-list-programs-item-content to-left">
      <div
        class="guide-list-programs-item"
        v-for="(channel, index) in channels"
        :key="index"
        :class="{
          focused: isFocusedChannel(channel),
        }"
      >
        <GuideTraditionalProgram
          v-for="(program, programIndex) in filteredPrograms(channel)"
          :key="programIndex"
          :title="getProgramTitle(program)"
          :is-live="getProgramIsLive(program)"
          :width="getWidthDuration(program)"
          :is-focused="isFocusedProgram(program)"
          :live-text="liveText"
          :class="{ disabled: isDisabled(program) }"
          @selected-program="onSelectedProgram(program)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GuideTraditionalProgram from "@/modules/guide/components/GuideTraditionalMain/GuideTraditionalProgram/GuideTraditionalProgram.vue";

import convertMillisecondsToMinutes from "@/modules/guide/helpers/convertMillisecondsToMinutes.js";

import {
  getPrograms,
  getChannelLcn,
  getChannelLogo,
} from "@/modules/guide/helpers/channel.js";

import {
  getProgramIsLive,
  getProgramStartTime,
  getProgramEndTime,
  getProgramTitle,
  getProgramId,
} from "@/modules/guide/helpers/program.js";

import { hasPassed24Hours } from "@/helpers/dateHelper.js";

/**
 * Cantidad de segundos que serán agregados al inicio
 * y final de la ventana de tiempo.
 * @private
 */
// const $_nowPositionHours = 60000 * 60 * 0.5;

export default {
  name: "GuideTraditionalListPrograms",

  inheritAttrs: false,

  components: {
    GuideTraditionalProgram,
  },

  props: {
    channels: {
      type: Array,
      required: false,
    },

    /**
     * Timestamp que representará el inicio de la línea de tiempo.
     */
    startTime: {
      type: Number,
      required: true,
    },

    /**
     * Timestamp que representará el final de la línea de tiempo.
     */
    endTime: {
      type: Number,
      required: true,
    },

    pixelsInOneMinute: {
      type: Number,
      required: false,
      default: 9,
    },

    /**
     * Canal activo.
     */
    focusedChannel: {
      type: Object,
      required: false,
    },

    /**
     * Programa activo.
     */
    focusedProgram: {
      type: Object,
      required: false,
    },

    /**
     * Texto que será mostrado en los programas en vivo.
     */
    liveText: {
      type: String,
      required: false,
      default: "VIVO",
    },

    /**
     * Función que permitirá el chequeo de control parental.
     */
    toCheckParentalControl: {
      type: Function,
      required: false,
      default: () => {},
    },

    /**
     * Texto que será mostrado en los programas bloqueados
     * por control parental.
     */
    parentalControlBlockedText: {
      type: String,
      required: false,
      default: "Bloqueado",
    },

    /**
     * canal al que se debe escrolear
     */
    scrolledChannel: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      cpChangedStatus: 1
    };
  },

  mounted() {
    this.$bus.$on("parentalControl.state_changed", this.updateParentalControl);
  },

  beforeDestroy() {
    this.$bus.$off("parentalControl.state_changed", this.updateParentalControl);
  },

  methods: {
    updateParentalControl() {
      //console.log('parentalControl.state_changed',this.cpChangedStatus);
      this.cpChangedStatus++;
    },
    filteredPrograms(channel) {
      let programs = getPrograms(channel);

      programs = programs.filter(
        (p) =>
          getProgramEndTime(p) > this.startTime &&
          getProgramStartTime(p) <= this.endTime
      );

      return programs;
    },

    getChannelLcn(channel) {
      return getChannelLcn(channel);
    },

    getChannelLogo(channel) {
      const logo = getChannelLogo(channel);

      if (!logo) {
        return null;
      }

      return "background-image: url(" + logo + ");";
    },

    getWidthDuration(program) {
      if (getProgramStartTime(program) < this.startTime) {
        return (
          convertMillisecondsToMinutes(
            getProgramEndTime(program) - this.startTime
          ) * this.pixelsInOneMinute
        );
      }

      return (
        convertMillisecondsToMinutes(
          getProgramEndTime(program) - getProgramStartTime(program)
        ) * this.pixelsInOneMinute
      );
    },

    getProgramIsLive(program) {
      return getProgramIsLive(program);
    },

    getProgramTitle(program) {
      this.cpChangedStatus;
      if (this.toCheckParentalControl(program)) {
        return this.parentalControlBlockedText;
      }

      return getProgramTitle(program);
    },

    isFocusedChannel(channel) {
      return getChannelLcn(this.focusedChannel) === getChannelLcn(channel);
    },

    isFocusedProgram(program) {
      return getProgramId(this.focusedProgram) === getProgramId(program);
    },

    isScrolledChannel(channel) {
      if (this.scrolledChannel) {
        return getChannelLcn(this.scrolledChannel) === getChannelLcn(channel);
      } else {
        return false;
      }
    },

    onSelectedProgram(program) {
      if (this.isDisabled(program)) {
        this.$bus.$emit("show-toast", "error", `No podemos reproducir el programa "${program.title}" por restricciones del canal.`);
      } else {
        this.$emit("selected-program", program);
      }
    },

    scrollToFocusedChannel(channel) {
      if (this.$refs) {
        const guide = document.getElementById("guide_programs");
        if (this.$refs["lcn" + channel.lcn]) {
          const [el] = this.$refs["lcn" + channel.lcn];

          //console.log('[ GUIDETRAD WEB ] scrollToFocusedChannel', el);
          if (el) {
            guide.scrollTop = el.offsetTop;
          }
        }
      }
    },
    isDisabled({ endHourUnixTime, fechaInicio }) {
      return hasPassed24Hours(endHourUnixTime, fechaInicio);
    }
  },
  watch: {
    scrolledChannel(value) {
      if (value) {
        this.scrollToFocusedChannel(value);
      }
    },
  },
};
</script>


/**
 * Convierte el timestamp enviado como parámetro a
 * una cadena de texto con la hora en formato HH:MM.
 *
 * @param {Number} date - Timestamp
 *
 * @returns {String} Formato HH:MM del timestamp enviado como parámetro.
 */
function formatTimestampToHHMM(date) {
	return [
		String(date.getHours()).padStart(2, 0),
		String(date.getMinutes()).padStart(2, 0),
	].join(':');
}

export default formatTimestampToHHMM;

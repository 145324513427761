/**
 * Agrega o quita una cantidad de horas determinadas
 * a la marca de tiempo especificada en parámetro.
 *
 * @param {Number} time - Timestamp
 * @param {Number} offset - Cantidad de horas a agregar
 * 							(en caso de indicar un número negativo
 * 							la suma se convierte en resta)
 *
 * @returns {Number} Nueva marca de tiempo.
 */
function dateSetHours(time, offset) {
	const date = new Date(time);
	return date.setHours(date.getHours() + offset);
}

export default dateSetHours;

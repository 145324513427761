<template>
  <div class="rail-guide-wrapper">
    <div class="guide-list-channels-column">
      <div
        v-show="$_showGuideLine"
        class="guide-line"
        :style="$_guideLineLeft"
      ></div>
      <GuideTraditionalTimeLine
        :start-time="startTime"
        :end-time="endTime"
        :pixels-in-one-minute="$_pixelsInOneMinute"
        :date-in-timeline="dateInTimeline"
      />
      <GuideTraditionalListPrograms
        :channels="channels"
        :start-time="startTime"
        :end-time="endTime"
        :pixels-in-one-minute="$_pixelsInOneMinute"
        :focused-channel="$_focusedChannel"
        :focused-program="$_focusedProgram"
        :live-text="liveText"
        :to-check-parental-control="toCheckParentalControl"
        :parental-control-blocked-text="parentalControlBlockedText"
        :scrolled-channel="scrolledChannel"
        @selected-program="onSelectedProgram"
      />
    </div>
  </div>
</template>

<script>
import GuideTraditionalTimeLine from "@/modules/guide/components/GuideTraditionalMain/GuideTraditionalTimeLine/GuideTraditionalTimeLine.vue";
import GuideTraditionalListPrograms from "@/modules/guide/components/GuideTraditionalMain/GuideTraditionalListPrograms/GuideTraditionalListPrograms.vue";

import convertSecondsToMs from "@/modules/guide/helpers/convertSecondsToMs.js";
import dateSetHours from "@/modules/guide/helpers/dateSetHours.js";
import dateSetDate from "@/modules/guide/helpers/dateSetDate.js";

import {
  getPrograms,
  getChannelIndexByLcn,
  getProgramIndexByIdAsset,
  getProgramsByChannelIndex,
} from "@/modules/guide/helpers/channel.js";

import {
  getProgramStartTime,
  getProgramEndTime,
  getProgramIndexByTimeWindow,
  getProgramIndexByTime,
} from "@/modules/guide/helpers/program.js";

/**
 * Temporizador para el refresco de programas en live.
 * @private
 */
let $_timerLive = null;

/**
 * Cantidad de segundos que serán agregados al inicio
 * y final de la ventana de tiempo.
 * @private
 */
const $_nowPositionHours = 60000 * 60 * 0.5;

/**
 * Cantidad de horas que serán renderizadas por página.
 * @private
 */
const $_hoursPerPage = 3;

export default {
  name: "GuideTraditionalMain",

  inheritAttrs: false,

  components: {
    GuideTraditionalTimeLine,
    GuideTraditionalListPrograms,
  },

  props: {
    /**
     * Arreglo de objetos Channel con propiedad programs.
     * La propiedad programs contendrá un arreglo de objetos Program.
     */
    channels: {
      type: Array,
      required: true,
    },

    /**
     * LCN del canal sobre el que se posicionará el foco.
     * En caso de no encontrarse dicho LCN en el arreglo Channels
     * se iniciará en el LCN del primer ítem del arreglo Channels.
     */
    focusOnChannelNumber: {
      type: Number,
      required: false,
      default: 2,
    },

    /**
     * Timestamp en donde se posicionará la ventana de tiempo.
     */
    focusOnTime: {
      type: Number,
      required: false,
      default: Date.now(),
    },

    /**
     * Cantidad máxima de días pasados, según la fecha presente,
     * que pueden ser navegados.
     */
    maxDaysBack: {
      type: Number,
      required: false,
      default: 1,
    },

    /**
     * Cantidad máxima de días futuros, según la fecha presente,
     * que pueden ser navegados.
     */
    maxDaysForward: {
      type: Number,
      required: false,
      default: 2,
    },

    /**
     * Cantidad de milisegundos que transcurrirán entre cada
     * actualización de la grilla. La grilla solamente será
     * actualizada si se encuentra en la ventana de tiempo "PRESENTE".
     */
    millisecondsToUpdate: {
      type: Number,
      required: false,
      default: 30000,
    },

    /**
     * Cantidad de píxeles que corresponderían a 10 minutos.
     *
     * TODO: Esto debería de ser dinámico según el perfil donde
     * se está renderizando el componente.
     */
    pixelsInTenMinutes: {
      type: Number,
      required: false,
      default: 90,
    },

    /**
     * Texto que será mostrado en los programas en vivo.
     */
    liveText: {
      type: String,
      required: false,
      default: "VIVO",
    },

    /**
     * Función que convertirá la fecha a mostrar en la
     * línea de tiempo.
     */
    dateInTimeline: {
      type: Function,
      required: true,
    },

    /**
     * Función que permitirá el chequeo de control parental.
     */
    toCheckParentalControl: {
      type: Function,
      required: false,
      default: () => {},
    },

    /**
     * Texto que será mostrado en los programas bloqueados
     * por control parental.
     */
    parentalControlBlockedText: {
      type: String,
      required: false,
      default: "Bloqueado",
    },

    /**
     * En web se necesita una variable que controle el elemento sobre el que se hace click
     * para seleccionar el canal/programa elegido.
     */
    clickProgram: {
      type: Object,
      required: false,
      default: () => {
        return {
          selectedChannel: 0,
          selectedProgram: 0,
        };
      },
    },

    /**
     * canal al que se debe escrolear
     */
    scrolledChannel: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      focusedChannelIndex: 0,
      focusedProgramIndex: 0,

      cursorTime: this.focusOnTime || Date.now(),
      startTime: 0,
      endTime: 0,

      liveRefreshDisabled: false,
    };
  },

  mounted() {},

  beforeDestroy() {
    this.$_disableLiveRefresh();
  },

  computed: {
    $_guideLineLeft() {
      /**
       * TODO: Esto está muy mal... no utilizar px estáticos (257px).
       * Se realizó ya que caso contrario habría que modificar el CSS de la maqueta.
       *
       * En cada perfil de pantalla esto debería de calcularse automáticamente o
       * mejor aún debe utilizarse el valor correcto en los CSS de la maqueta y
       * así poder ahorrar este cálculo.
       */
      return (
        "left: calc(257px + " +
        ((convertSecondsToMs(this.cursorTime) - this.startTime) / 1000 / 60) *
          this.$_pixelsInOneMinute +
        "px);"
      );
    },

    $_pixelsInOneMinute() {
      //console.log('$_pixelsInOneMinute', this.pixelsInTenMinutes);
      return this.pixelsInTenMinutes / 10;
    },

    $_focusedChannel() {
      return this.channels[this.focusedChannelIndex];
    },

    $_focusedProgram() {
      const programs = getPrograms(this.$_focusedChannel);

      return programs[this.focusedProgramIndex];
    },

    $_showGuideLine() {
      const currentTime = Date.now();

      const actualPosition =
        ((convertSecondsToMs(this.cursorTime) - this.startTime) / 1000 / 60) *
        this.$_pixelsInOneMinute;
      const referPosition =
        ((convertSecondsToMs(currentTime) - this.startTime) / 1000 / 60) *
        this.$_pixelsInOneMinute;

      //+- 5 de tolerancia
      const limitLeft = referPosition - 5;
      const limitRight = referPosition + 5;

      const show = limitLeft <= actualPosition && actualPosition <= limitRight;

      if (show) {
        this.liveRefreshDisabled = false;
        this.$_enableLiveRefresh();
        this.$emit("live-refresh", { status: "on" });
      } else {
        this.liveRefreshDisabled = true;
        this.$_disableLiveRefresh();
        this.$emit("live-refresh", { status: "off" });
      }

      return show;
    },
  },

  methods: {
    $_init() {
      if (this.focusedChannelIndex === 0) {
        this.$_setFocusedChannelByLcn(this.focusOnChannelNumber);
      }

      this.$_setFocusedProgramByTime(this.getFocusedChannel(), this.cursorTime);

      // Inicializo la ventana de tiempo.
      this.$_generateTimeWindow(this.cursorTime);
    },

    /**
     * Genera la ventana de tiempo a visualizar según
     * la marca de tiempo indicada y dispara el evento page-turn.
     *
     * @param {Number} time - Timestamp sobre el cual se posicionará el cursor.
     *
     * @private
     */
    $_generateTimeWindow(time) {
      const now = new Date();

      const timestampBack = dateSetDate(now, -this.maxDaysBack);

      const timestampForward = dateSetDate(now, this.maxDaysForward);

      // Controlo no pasarme de la ventana de tiempo tanto
      // en el presente como en el futuro.
      this.cursorTime = convertSecondsToMs(
        time <= timestampBack
          ? timestampBack
          : time >= timestampForward
          ? timestampForward
          : time
      );

      this.startTime = this.cursorTime - $_nowPositionHours;

      this.endTime = dateSetHours(this.startTime, $_hoursPerPage);

      this.$_emitPageTurn();
    },

    /**
     * @private
     */
    $_emitPageTurn() {
      this.$emit("page-turn", {
        startTime: this.startTime,
        endTime: this.endTime,
        cursorTime: this.cursorTime,
      });
    },

    $_enableLiveRefresh() {
      $_timerLive = setTimeout(() => {
        if (!this.liveRefreshDisabled) {
          this.$_generateTimeWindow(Date.now());
        }
      }, this.millisecondsToUpdate);
    },

    $_disableLiveRefresh() {
      clearTimeout($_timerLive);
    },

    /**
     * Retorna el canal donde actualmente se encuentra el foco.
     *
     * @returns {Channel}
     */
    getFocusedChannel() {
      return this.$_focusedChannel;
    },

    /**
     * Setea el foco sobre un canal según su LCN.
     *
     * @private
     *
     * @param {Number} lcn - LCN de canal.
     */
    $_setFocusedChannelByLcn(lcn = 1) {
      this.focusedChannelIndex = getChannelIndexByLcn(this.channels, lcn);
    },

    /**
     * Retorna el programa donde actualmente se encuentra el foco.
     *
     * @returns {Program}
     */
    getFocusedProgram() {
      return this.$_focusedProgram;
    },

    /**
     * Setea el foco en un programa según el canal y tiempo indicado.
     *
     * @param {Channel} channel - Objeto de canal que contiene la propiedad programs.
     * @param {Number} time - Tiempo sobre el cual se buscará el programa a setear el foco.
     *
     * @private
     */
    $_setFocusedProgramByTime(channel, time) {
      this.focusedProgramIndex = getProgramIndexByTime(
        getPrograms(channel),
        time
      );

      this.$_emitFocusedProgram();
    },

    /**
     *
     * @private
     */
    $_emitFocusedProgram() {
      this.$emit("program-focus", this.getFocusedProgram());
    },

    onSelectedProgram(program) {
      //console.log('[ GUIDETRAD ] onSelectedProgram ', program);
      let lcn = null;
      let idAsset = null;
      if (program && program.channel) {
        lcn = program.channel.lcn;
        idAsset = program.idAsset;
      }
      //channel
      this.focusedChannelIndex = getChannelIndexByLcn(this.channels, lcn);
      this.$_init();

      const programs = getProgramsByChannelIndex(
        this.channels,
        this.focusedChannelIndex
      );
      //program
      this.focusedProgramIndex = getProgramIndexByIdAsset(programs, idAsset);

      this.$_emitFocusedProgram();
    },
  },

  watch: {
    channels() {},

    focusOnChannelNumber() {
      this.focusedChannelIndex = 0;
      this.$_init();
    },

    focusOnTime(value) {
      this.$_generateTimeWindow(value);
      //this.focusedProgramIndex = -1;
    },

    // pixelsInTenMinutes() {
    // 	this.$_generateTimeWindow(this.focusOnTime);
    // },
  },
};
</script>

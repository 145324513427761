/**
 * Convierte un unix time a milisegundos.
 *
 * @param {Number} seconds - Unix time
 *
 * @returns {Number}
 */
function convertSecondsToMs(seconds) {
	if (!seconds) {
		return 0;
	}
	return seconds < 9999999999 ? seconds * 1000 : seconds;
}

export default convertSecondsToMs;

<template>
  <div
    class="guide-program"
    :class="{ 'guide-program-live-bg': isLive, focused: isFocused }"
    :style="{ width: width + 'px' }"
    v-on:click="selectProgram"
  >
    <p>{{ title }}</p>
    <span v-if="isLive" class="guide-program-live">{{ liveText }}</span>
  </div>
</template>

<script>
export default {
  name: "GuideTraditionalProgram",

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    isLive: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: Number,
      default: 0,
      required: true,
    },
    isFocused: {
      type: Boolean,
      default: false,
      required: false,
    },

    /**
     * Texto que será mostrado en los programas en vivo.
     */
    liveText: {
      type: String,
      required: false,
      default: "VIVO",
    },
  },

  methods: {
    selectProgram() {
      this.$emit("selected-program");
    },
  },
};
</script>
